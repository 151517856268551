// extracted by mini-css-extract-plugin
export var anotherContainer = "index-module--anotherContainer--9UsqH";
export var btn = "index-module--btn--vifLg";
export var buttonContainer = "index-module--buttonContainer--vXOJL";
export var carouselContainer = "index-module--carouselContainer--S7Nnv";
export var dotul = "index-module--dotul--Nw-vS";
export var flowball = "index-module--flowball--J16-6";
export var footerBanner = "index-module--footerBanner--OKkDs";
export var footerBtn = "index-module--footerBtn--rbhSU";
export var footerImgContainer = "index-module--footerImgContainer--GMoTD";
export var group = "index-module--group--VvHEc";
export var groupItem = "index-module--groupItem--cBtNb";
export var imgContainer = "index-module--imgContainer--vmBMj";
export var line = "index-module--line--fzrP0";
export var loadMore = "index-module--loadMore--Ugi29";
export var loadMoreBtn = "index-module--loadMoreBtn--CmyE0";
export var no_data = "index-module--no_data--6ff-I";
export var rectangle = "index-module--rectangle--Pi3H2";
export var shoprises = "index-module--shoprises--qGMIw";
export var showIndex = "index-module--showIndex--XDE4D";
export var smallShow = "index-module--smallShow--XHYvq";
export var text = "index-module--text--FrC5W";
export var textContainer = "index-module--textContainer--nt0ed";
export var title = "index-module--title--hnHeS";