//shoprises 有品页面的数据展示图

import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
const OwnChart = ({ data = [], id }) => {
  useEffect(() => {
    const chart = new Chart({
      container: "chartContainer" + id,
      autoFit: true,
      height: 160,
    });
    const value = [];
    data.forEach((item) => {
      value.push({
        year: item.ad_date?.substr(5),
        "投放数：": item.ad_num,
      });
    });
    const defs = {
      year: {
        type: "time",
      },
    };

    chart.source(value, defs);
    chart.scale("投放数：");
    chart.scale("year", {
      range: [0, 1],
      tickCount: 4,
    });
    chart.tooltip({
      crosshairs: {
        type: "line",
      },
    });
    chart.line().position("year*投放数：");
    chart.point().position("year*投放数：").size(0).shape("circle").style({
      stroke: "#fff",
      lineWidth: 1,
    });
    chart.render();
  }, []);
  return <div id={"chartContainer" + id} style={{ padding: "0 3px" }} />;
};

export default OwnChart;
