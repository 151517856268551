export default (beforeUrl) => {
  console.log(process.env.NODE_ENV);

  // let afterUrl =
  //   "https://shoprisespy.apps.seabroadnet.com/media_proxy" + beforeUrl;

    let afterUrl =
    "/media_proxy" + beforeUrl;
  return afterUrl;
};
