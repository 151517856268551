import React, { useEffect, useRef, useState } from "react";

import { Button, Carousel, Col, Row, Spin } from "antd";
import { Helmet } from "react-helmet";

import Footer from "@components/footer";
import Header from "@components/header";
import OwnChart from "@components/ownChart";
import pathModify from "../../utils/pathModify";
import * as styles from "./index.module.scss";

import { graphql, navigate } from "gatsby";
import Img from "gatsby-image";

import nodataimg from "@images/shoprises/nodata.png";

const handleRegister = (event) => {
  event.preventDefault();
  navigate("/register");
};

const Nodata_data = [
  "Shoprise有品是跨境电商行业内最强大的数据选品工具，每日更新当前市面上最热门的商品及其广告数据。",
  "支持多达20个维度筛选商品，包括产品IP、语种、分析模型、广告类型等独有维度；",
  "支持Apple app / Facebook app / Google app / Magento / Opencart / Shopify / Zencart等主流平台定向筛选；",
  "支持Facebook / Instagram / Audience network / Messenger等主流广告平台定向筛选。",
];
const Nodata_ul = [
  "●Shoprise的广告投放趋势可以评估商品热度，适合独立站打造爆款商品的跨境电商卖家",
  "●Shoprise可以通过关键词搜索来筛选广告数据，适用于希望通过差异化销售获取新客户的跨境电商卖家",
  "●Shoprise支持显示多个广告平台和电商平台的广告数据，适用于希望扩大信息源来寻找选品灵感的跨境电商卖家",
  "●Shoprise为跨境电商卖家商品测款时提供辅助服务",
  "●Shoprise提供多种精细商品的广告数据，适合于实时进行高效、轻松选品的跨境电商卖家",
];
// http://staging-prspy-lf.sz1.codefriend.top/api/selection/index
const Shoprises = ({ data }) => {
  //定义一些数据
  const [renderData, setRenderData] = useState([]); //移动端的数据
  const [pcRenderData, setPcRenderData] = useState([]); //pc端的数据
  const [loading, setLoading] = useState(true); //pc 端的loading
  const [smallLoading, setSmallLoading] = useState(true); //移动端的loading
  const flowball = useRef(); //小球的ref
  const group = useRef(); //列表的ref
  const [showIndex, setShowIndex] = useState(0); //定义移动端显示到多少个图片
  const [isReachBottom, setIsReachBottom] = useState(false); //是否到达底部

  const [isHaveData, setIsHaveData] = useState(true);

  //定义获取页面数据的方法
  const getPageData = (page) => {
    // setSmallLoading(true);
    // setLoading(true)
    return fetch(
      `https://shoprisespy.apps.seabroadnet.com/api/selection/external_selection_list?page=${page}`,

      // `/selection/external_selection_list?page=${page}`,
      {
        headers: {
          Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NDZkMDc5Yi04OTMzLTRiN2MtODM5ZC1iMzA4MTNjMzRhOTEiLCJqdGkiOiJjODJkZmI1NzQxZjc2YTZhODE4OWIwNjE2ODkxYTAzNDQ0MzAzZGM5ZGU4OTYwY2Y1ZDhkZWVmNWYxYzA4N2QxMmVkMzhkN2EwYTJjNWZhZCIsImlhdCI6MTY2NTIxMTEzMy4yMDI5NDgsIm5iZiI6MTY2NTIxMTEzMy4yMDI5NjYsImV4cCI6NDc4OTM0ODczMy4xNTU4ODcsInN1YiI6IjEiLCJzY29wZXMiOlsic2VsZWN0aW9uIl19.1MSDUOCiVibFdPpmA6aHjiBbmB4AN0cOsPRsuyM6gODW1ifOdyOQy1U36c1R-J8fcPNHWvhuL9QgZwxsxtRft0GW1t__X3HgbrH-AZtNwwTUuoDLeFN5kKnDytxRh4bQgfklKwpnd0MvPEu0ppoE4nr_xKepEPHEwVapWZBLXT3ESI0-Tc0thEp5iE9UhToBN7KQga2hbvGHERDH3ofaLZc1cQetqhWn3eVdnOKEOAH2PyYOE_8qdQLD3cvkH6umwGk3nrSON6cDO7Viik9YncGW7WCriCScfoJHGgilxApWQHR2rW5LQwA3JCcCRQw72h_U5IGEM4J8QuBeQ682Ig_gjuIc5TpciDKseQflOpX9RgCf1mh79ajx2cmrHyU-tndR5WfolZcg3EARS-7_weOVF9yIKIqK6c5S98_5ksnX-hK2NF8IlZ3K473-rhEjcZnr9tncST6RFO3kt0P4314FJU5SNQHg9yl1FUcEJfRAgPzdUUEhTwGh0wUs8Nshgx8NCZt_WqQg5Q0C3Yv0RjmNaa89wcR15C9MvSYGIyCglPSYz7PjInbzwymdKBU3ri9nw6o9q9LQ0_I7MKlkCVRSTZ1UT9x-G4vakpvB7HOkeyFoDkIUNtKrQtZwWYB3ZMjJuoqGlRwZo5kMrhJkryylS65Os_e1PR9u_6Bxe4Q"
        },
      }
    )
      .then((res) => res.json())
      .then(
        ({ data }) => {
          return data;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  //在页面初始化的时候加载pc的20条数据
  useEffect(async () => {
    let pcPage1Data = await getPageData(1);
    if (pcPage1Data) {
      setLoading(false);
      setPcRenderData([...pcPage1Data]);
    }
    //如果没有数据
    if (!pcPage1Data.length) {
      setIsHaveData(true);
    }
  }, []);

  //在页面初始化的时候加载移动端的40条数据
  useEffect(async () => {
    let page1Data = await getPageData(1);
    let page2Data = await getPageData(2);
    if (page1Data && page2Data) {
      setSmallLoading(false);
      setRenderData([...page1Data, ...page2Data]);
    }
  }, []);

  //pc端加载更多
  const loadMore = async () => {
    console.log("加载更多");
    setLoading(true);
    if (pcRenderData.length < 40) {
      let pcPage2Data = await getPageData(2);
      if (pcPage2Data) {
        setLoading(false);
        setPcRenderData([...pcRenderData, ...pcPage2Data]);
      }
    }
  };

  //页面滚动监听 滚动到底部小球隐藏
  /*   useEffect(() => {
      window.onscroll = () => {
        let bottom = group.current.getBoundingClientRect().bottom
        if (bottom < 800) {
          setIsReachBottom(true)
        }
        else {
          setIsReachBottom(false)
        }
      }
      return () => {
        window.onscroll = null
      }
    })
    console.log('loading', loading);
    */

  return (
    <div className={styles.shoprises}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>shoprise有品-Shoprise 官网</title>
      </Helmet>

      <Header />

      {/* 页面有数据的时候 */}
      {isHaveData && (
        <>
          <div className={styles.title}>
            <h1>Shoprise有品</h1>
            <h4>昨日TOP40商品&广告</h4>
          </div>
          <Row>
            {/* pc端显示 */}
            <Col xs={0} sm={0} md={24}>
              <div className={styles.group} ref={group}>
                {pcRenderData.map((item, index) => {
                  return (
                    <div className={styles.groupItem} key={index}>
                      <div className={styles.imgContainer}>
                        <img
                          src={pathModify(item.image_url)}
                          alt="这里是一张图片"
                        />
                        {/* <img src={'/media_proxy' + item.image_url} alt="this is a photo " /> */}
                      </div>
                      <div className={styles.anotherContainer}>
                        <div className={styles.text}>
                          {item.desc_info ? item.desc_info : <br />}
                        </div>
                        <div className={styles.text}>
                          {item.domain_name} （{item.platform}）
                        </div>
                        <div className={styles.text}>
                          产品分类:{item.labels[0]}
                        </div>
                        <div className={styles.text}>
                          最近启投时间：{item.startDate}
                        </div>
                        <div className={styles.text}>
                          投放权重:{item.total_active_2week}
                        </div>
                        <div className={styles.text}>
                          Page Like：{item.page_like}
                        </div>
                        <div className={styles.buttonContainer}>
                          <button disabled>文案信息</button>
                          <button disabled>查看视频</button>
                          <button disabled>查看广告</button>
                          <button disabled>店铺广告</button>
                        </div>
                        <div
                          className={styles.text}
                          style={{
                            marginTop: "5px",
                            color: "#B2B2B2",
                          }}
                        >
                          广告投放趋势图
                        </div>
                        <OwnChart data={item.ads_num} id={index} />
                      </div>
                    </div>
                  );
                })}
              </div>
              {loading ? (
                <div
                  style={{ width: "100%", height: "80px", textAlign: "center" }}
                >
                  <Spin size="large" />
                </div>
              ) : (
                <div
                  className={styles.loadMore}
                  style={
                    pcRenderData.length > 20
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  <Button onClick={loadMore} className={styles.loadMoreBtn}>
                    加载更多
                  </Button>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={0}>
              <div className={styles.carouselContainer}>
                {smallLoading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                      textAlign: "center",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : (
                  <Carousel
                    dots={false}
                    infinite={false}
                    afterChange={(index) => {
                      setShowIndex(index);
                    }}
                  >
                    {renderData.map((item, index) => {
                      return (
                        <div className={styles.groupItem} key={index}>
                          <div className={styles.imgContainer}>
                            {item.image_url ? (
                              <img
                                src={pathModify(item.image_url)}
                                alt="这里是一张图片"
                              />
                            ) : (
                              // <img src={'/media_proxy' + item.image_url} alt="this is a photo " />
                              ""
                            )}
                          </div>
                          <div className={styles.anotherContainer}>
                            <div className={styles.text}>
                              {item.desc_info ? item.desc_info : <br />}
                            </div>
                            <div className={styles.text}>
                              {item.domain_name} （{item.platform}）
                            </div>
                            <div className={styles.text}>
                              产品分类:{item.labels[0]}
                            </div>
                            <div className={styles.text}>
                              最近启投时间：{item.startDate}
                            </div>
                            <div className={styles.text}>
                              投放权重:{item.total_active_2week}
                            </div>
                            <div className={styles.text}>
                              Page Like：{item.page_like}
                            </div>
                            <div className={styles.buttonContainer}>
                              <button disabled>文案信息</button>
                              <button disabled>查看视频</button>
                              <button disabled>查看广告</button>
                              <button disabled>店铺广告</button>
                            </div>
                            <div
                              className={styles.text}
                              style={{
                                marginTop: "5px",
                              }}
                            >
                              广告投放趋势图
                            </div>
                            <OwnChart
                              data={item.ads_num}
                              id={"small" + index}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                )}
                <div className={styles.showIndex}>（{showIndex + 1}/40）</div>
              </div>
            </Col>
          </Row>

          <div className={styles.smallShow}>
              <Button
                type="primary"
                className={styles.btn}
                onClick={handleRegister}
              >
                探索更多信息
              </Button>
          </div>
        </>
      )}
      {/* 页面没有数据的时候 */}
      {!isHaveData && (
        <div className={styles.no_data} ref={group}>
          <div className={styles.title}>
            <h1>Shoprises有品</h1>
          </div>
          <div className={styles.imgContainer}>
            <img src={nodataimg} alt="nodata" />
            <div className={styles.text}>商品广告数据跑丢了，请刷新试试</div>
          </div>
          <div className={styles.textContainer}>
            {Nodata_data.map((item) => (
              <div key={item}>{item}</div>
            ))}
          </div>
          <div className={styles.line} />
          <ul className={styles.dotul}>
            {Nodata_ul.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      )}

      {/*       <div
        className={styles.flowball}
        ref={flowball}
        //判断小球是否可见
        style={isReachBottom ? { opacity: 0 } : { opacity: 1 }}
        role="button"
        onClick={handleRegister}
      >
        探索 <br />
        更多信息
      </div> */}
      <div className={styles.footerBanner}>
        <div className={styles.footerImgContainer}>
          <Row>
            <Col xs={0} sm={0} md={24}>
              <Img fluid={data.footerBg.childImageSharp.fluid} />
            </Col>
            <Col xs={24} sm={24} md={0}>
              <Img fluid={data.smallFooterBg.childImageSharp.fluid} />
            </Col>
          </Row>


            <Button
              className={styles.footerBtn}
              type="round"
              onClick={handleRegister}
            >
              立即探索更多
            </Button>
        </div>
        <div className={styles.rectangle} />
      </div>
      <Footer />
    </div>
  );
};
export default Shoprises;

export const query = graphql`
  query {
    footerBg: file(relativePath: { eq: "shoprises/footerBanner.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    smallFooterBg: file(
      relativePath: { eq: "shoprises/smallFooterBanner.png" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
